import {onDomReady} from './modules/utils';
import Project from './project';

// Load all SVGs to combine them into a sprite
require.context('../svg', true, /\.svg$/);

/**
 * Navigation
 *
 * Manage the navigation panel
 */
function navigation() {
    const documentBody = document.querySelector('body'),
        navigation = document.querySelector('.js-navigation'),
        navigationPanel = navigation.querySelector('.js-navigation-panel'),
        focusabledElsList = 'a[href]:not([disabled]), button:not([disabled]), [tabindex]:not([tabindex="-1"])',
        everyfocusableEls = navigationPanel.querySelectorAll(focusabledElsList),
        firstFocusableEl = everyfocusableEls[0],
        key = {
            escape: 27,
            tab: 9,
            enter: 13,
            space: 32,
        };

    /**
     * Trap focus inside an element
     */
    function trapFocus(e) {
        let element = this,
            focusableEls = element.querySelectorAll(focusabledElsList),
            firstFocusableEl = focusableEls[0],
            lastFocusableEl = focusableEls[focusableEls.length - 1],
            isTabPressed = (e.key === 'Tab' || e.keyCode === key.tab);

        if (!isTabPressed) {
            return;
        }

        if (e.shiftKey) /* shift + tab */ {
            if (document.activeElement === firstFocusableEl) {
                lastFocusableEl.focus();
                e.preventDefault();
            }
        } else /* tab */ {
            if (document.activeElement === lastFocusableEl) {
                firstFocusableEl.focus();
                e.preventDefault();
            }
        }
    }

    /**
     * Function to close the main navigation
     */
    function closeNavigation() {
        // We remove these classes to hide our navigation
        documentBody.classList.remove('is-navigation-open', 'u-noScroll');

        // Remove event listener that traps focus inside the navigation
        navigationPanel.removeEventListener('keydown', trapFocus, true);

        // Reset aria attribute on the main navigation trigger
        document.querySelector('.js-toggle-navigation').setAttribute('aria-expanded', 'false');
    }

    /**
     * Function to hide/close with escape key
     */
    function closeSubNavOnEscape(event) {
        if (event.keyCode === key.escape) {
            // If the main navigation is open
            if (documentBody.classList.contains('is-navigation-open')) {
                // Close it
                closeNavigation();

                // Set focus back to the element that previously opened the navigation
                document.querySelector('.js-toggle-navigation').focus();
            }
        }
    }

    navigation.addEventListener('keydown', closeSubNavOnEscape);

    /**
     * We use a listener on the end of the animation that hides our navigation,
     * It allows us to animate a fade out and then hide the navigation with `display: none;`
     */
    navigationPanel.addEventListener('animationend', function handler(e) {
        // We check if our "fade out" animation has ended
        if (e.animationName !== 'move-to-top-fade-out-to-none') {
            return;
        }
        // And if so, we remove the class last class that keep our navigation from being hidden
        documentBody.classList.remove('is-navigation-closing');
    }, true);

    document.addEventListener('click', function (event) {
        var clickedEl = event.target,
            thisTriggerNavigation = clickedEl.closest('.js-toggle-navigation'),
            thisNavigation = clickedEl.closest('.js-navigation');

        // Click to display the navigation
        if (thisTriggerNavigation) {
            // If the navigation toggle is meant to close the navigation
            if (clickedEl.closest('[data-togglenav-close]')) {
                closeNavigation();

                // Set focus back to the element that previously opened the navigation
                document.querySelector('.js-toggle-navigation').focus();
            } else {
                // We add this class to make our navigation visible
                documentBody.classList.add('is-navigation-open', 'is-navigation-closing', 'u-noScroll');

                // Add proper aria attribute for screen readers
                thisTriggerNavigation.setAttribute('aria-expanded', 'true');

                // Add an event listener to trap the focus inside the navigation
                navigationPanel.addEventListener('keydown', trapFocus, true);

                // Add focus to the first element
                firstFocusableEl.focus();
            }
        }
    }, false);
}

/**
 * Animate tagline
 *
 * Rotate the list of words in the tagline
 */
function animatedTagline(tagline, delay) {
    tagline.forEach(function (element) {
        setTimeout(function () {
            var firstWord = element.querySelector('.is-in') ? element.querySelector('.is-in') : element.firstElementChild;
            hideWord(firstWord);
        }, delay);
    });

    function hideWord(word) {
        var nextWord = takeNext(word);

        switchWord(word, nextWord);
        setTimeout(function () {
            hideWord(nextWord);
        }, delay);
    }

    function takeNext(word) {
        return (!word.matches(':last-child')) ? word.nextElementSibling : word.parentNode.firstElementChild;
    }

    function switchWord(oldWord, newWord) {
        oldWord.classList.remove('is-in');
        oldWord.classList.add('is-out');
        newWord.classList.remove('is-out');
        newWord.classList.add('is-in');
    }
}

/**
 * Tabs/Accordion for the services
 *
 * Reveal content of each services on click/mouseover
 */
function servicesTabs(tabTrigger) {
    function toggleAccordion(event) {
        // Reset all opened accordions
        var allAccordions = document.querySelectorAll(tabTrigger);
        for (var i = 0; i < allAccordions.length; i++) {
            var matchingContent = document.querySelector('#' + allAccordions[i].getAttribute('aria-controls'));
            allAccordions[i].setAttribute('aria-expanded', 'false');
            matchingContent.classList.remove('is-open');
        }

        // Get our current accordion
        var thisAccordion = event.target.closest(tabTrigger);
        var thisMatchingContent = document.querySelector('#' + thisAccordion.getAttribute('aria-controls'));

        // Switch the aria state
        thisAccordion.setAttribute('aria-expanded', 'true');
        // Switch the content's visibility
        thisMatchingContent.classList.add('is-open');

        // On small screens, realign the window with the open accordion
        if (window.matchMedia('(max-width: 47.98rem)').matches) {
            thisAccordion.scrollIntoView();
        }
    }

    document.querySelectorAll(tabTrigger).forEach(function (el) {
        el.addEventListener('click', toggleAccordion, false);

        // If the device has hovering capabilities
        if (window.matchMedia('(hover: hover)').matches) {
            el.addEventListener('mouseenter', toggleAccordion, false);
        }
    });
}

onDomReady(() => {
    navigation();
    animatedTagline(document.querySelectorAll('.js-tagline'), 4500);

    // Homepage
    servicesTabs('.js-services-tab');
    new Project();
});
