export default class ProjectsSectionsComponent {
    static init() {
        const projectsSections = document.querySelectorAll('.projectsSection');

        if (projectsSections.length > 0) {
            projectsSections.forEach(projectsSection => {
                this.setNavigationCounter(projectsSection);
                this.attachNavArrowsListener(projectsSection);
                this.modalPoppinListingClients();
            });
        }
    }

    static attachNavArrowsListener(projectsSection) {
        const projectsSectionNav = projectsSection.querySelector('.projectsSection-pagination');
        if (projectsSectionNav) {
            const projectsSectionSwiperAPI = projectsSection.querySelector('.projectsSection-offset').swiper;
            if (projectsSectionSwiperAPI) {
                projectsSectionSwiperAPI.on('slideChange', (projectsSectionSwiper) => {
                    const projectsSectionActiveSlideIndex = projectsSectionSwiper.activeIndex + 1;
                    const paginationCurrent = projectsSectionNav.querySelector('.pagination-current');
                    if (paginationCurrent) {
                        paginationCurrent.textContent = projectsSectionActiveSlideIndex < 10 ? `0${projectsSectionActiveSlideIndex}` : projectsSectionActiveSlideIndex;
                    }
                });
            }
        }
    }

    static setNavigationCounter(projectsSection) {
        const projectsSectionNav      = projectsSection.querySelector('.projectsSection-pagination');
        const projectsSectionCarousel = projectsSection.querySelector('.projectsSection-carousel');
        let nbProjectsSectionItems = 0;
        if ( projectsSectionCarousel ) {
          nbProjectsSectionItems  = projectsSectionCarousel.querySelectorAll('.projectsSection-item:not(.swiper-slide-duplicate)').length;
        }

        if (nbProjectsSectionItems > 0 && projectsSectionNav) {
            const totalCountElement = projectsSectionNav.querySelector('.pagination-total');
            if (totalCountElement) {
                totalCountElement.textContent = nbProjectsSectionItems < 10 ? `0${nbProjectsSectionItems}` : nbProjectsSectionItems;
            }

            // TODO : put slide number in DOM and add onChange event on slideshow to set new slide numbre after each change
            if (projectsSectionCarousel.classList.contains('swiper-wrapper')) {
                const projectsSectionSwiperAPI = projectsSection.querySelector('.projectsSection-offset').swiper;
                const projectsSectionActiveSlideIndex = projectsSectionSwiperAPI.activeIndex + 1;
                const paginationCurrent = projectsSectionNav.querySelector('.pagination-current');
                if (paginationCurrent) {
                    paginationCurrent.textContent = projectsSectionActiveSlideIndex < 10 ? `0${projectsSectionActiveSlideIndex}` : projectsSectionActiveSlideIndex;
                }
            }
        }
    }

    static modalPoppinListingClients() {
        const body = document.querySelector('body');
        const footer = document.querySelector('footer');
        const projectSection = document.querySelector('section');
        const header = document.querySelector('.header');
        const container1 = document.querySelector('.wp-block-group:nth-of-type(1)');
        const container2 = document.querySelector('.wp-block-group:nth-of-type(2)');
        const container3 = document.querySelector('.wp-block-group:nth-of-type(3)');
        const projectsSectionAccroche = document.querySelector('.projectsSection-accroche');
        const projectsSectionAccrocheButton = document.querySelector('.projectsSection-accroche-buton');
        const projectsSectionModal = document.querySelector('.modal-listing-client');
        const close = document.querySelector('.modal-listing-client .close');
        if (projectsSectionModal) {
            if (projectsSectionAccroche) {
                projectsSectionAccroche.addEventListener('click', function () {
                    footer.classList.add('d-none');
                    projectSection.classList.add('d-none');
                    projectsSectionModal.classList.remove('d-none');
                    body.style.overflow = 'hidden';
                });
            }

            if (projectsSectionAccrocheButton) {
                projectsSectionAccrocheButton.addEventListener('click', function () {
                    header.classList.add('z-un');
                    footer.classList.add('d-none');
                    container1.classList.add('d-none');
                    container2.classList.add('d-none');
                    container3.querySelector('.row').classList.add('d-none');
                    container3.classList.add('h-100');
                    projectSection.classList.add('d-none');
                    projectsSectionModal.classList.remove('d-none');
                    body.style.overflow = 'hidden';
                });

            }

            if (close) {
                close.addEventListener('click', function () {
                    if (header.classList.contains('z-un')) {
                        header.classList.remove('z-un');
                    }
                    footer.classList.remove('d-none');
                    projectSection.classList.remove('d-none');
                    projectsSectionModal.classList.add('d-none');
                    body.style.overflow = 'initial';
                    if (container1) {
                        container1.classList.remove('d-none');
                    }
                    if (container2) {
                        container2.classList.remove('d-none');
                    }
                    if (container3) {
                        container3.querySelector('.row').classList.remove('d-none');
                        container3.classList.remove('h-100');
                    }
                });
            }
        }
    }
}
