import ProjectsSectionsComponent from './components/projectsSection';
import Animations from './modules/animations';

export default class Project {
    constructor() {
        this.init();
    }

    init() {
        Animations.init();
        ProjectsSectionsComponent.init();
    }
}
