import Swiper from 'swiper';
import {Keyboard, Navigation, Pagination} from 'swiper/modules';

export function createSwiper(swiperElement = '.swiper', config = {}) {
    const defaultConfig = {
        modules: [Keyboard, Navigation, Pagination],
        createElements: true,
        loop: false,
        keyboard: {
            enabled: true,
        },
    };
    const container = (typeof swiperElement === 'string') ? document.querySelector(swiperElement) : swiperElement;
    config = Object.assign({}, defaultConfig, config);

    if (!container.classList.contains('swiper')) {
        container.classList.add('swiper');
        const swipperWrapper = container.querySelector(`.${config.wrapperClass}`);
        swipperWrapper.classList.add('swiper-wrapper');
        const swipperSlides = swipperWrapper.querySelectorAll(`.${config.slideClass}`);
        swipperSlides.forEach(swipperSlide => swipperSlide.classList.add('swiper-slide'));
    }

    return new Swiper(container, config);
}
